




















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getsearchSucceedCase } from "@/api/home";
import { getArticleList } from "@/api/news";
@Component({
  components: {},
})
export default class Search extends Vue {
  current = 1; //当前页数
  pageSize = 10; //每页条数
  total = 0; //总条数

  succeedCaseVosList = [];
  Articlelist = [];
  created() {
    getsearchSucceedCase({ miniTitle: this.$route.query.search }).then(
      (res) => {
        res.data.forEach((item: any) => {
          item.identification = true;
        });
        this.succeedCaseVosList = res.data;
      }
    );
    getArticleList({
      current: this.current,
      size: this.pageSize,
      articleTitle: this.$route.query.search,
    }).then((res) => {
      this.Articlelist = res.data.list;
      this.total = res.data.total;
    });
  }
  @Watch("$route")
  search() {
    this.succeedCaseVosList = [];
    this.Articlelist = [];
    getsearchSucceedCase({ miniTitle: this.$route.query.search }).then(
      (res) => {
        res.data.forEach((item: any) => {
          item.identification = true;
        });
        this.succeedCaseVosList = res.data;
      }
    );
    getArticleList({
      current: this.current,
      size: this.pageSize,
      articleTitle: this.$route.query.search,
    }).then((res) => {
      this.Articlelist = res.data.list;
      this.total = res.data.total;
    });
  }
  Jump(id: any) {
    // this.$router.push({ path: "/newsdetails", query: { id } });
    const routeUrl = this.$router.resolve({
      path: '/newsdetails',
      query: { id }
    })
    window.open(routeUrl.href, '_blank')
  }
}
